/* eslint-env node */

/*
 * This file is automatically compiled by Webpack, along with any other files
 * present in this directory. You're encouraged to place your actual application logic in
 * a relevant structure within app/javascript and only use these pack files to reference
 * that code so it'll be compiled.
 */

window.Rails = require('@rails/ujs');
require('turbolinks').start();// Support component names relative to this directory:
const componentRequireContext = require.context('components', true);
const ReactRailsUJS = require('react_ujs');
ReactRailsUJS.useContext(componentRequireContext);
window.Rails.start();

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import '../scss/application.scss';