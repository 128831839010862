import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {DayPickerRangeController} from 'react-dates';
import moment from 'moment';

const Calendar = ({date}) => {
  const [state, setState] = useState({
    focusedInput: 'startDate',
    startDate: moment(date).startOf('week'),
    endDate: moment(date).endOf('week')
  });
  const {focusedInput, startDate, endDate} = state;

  return (
    <DayPickerRangeController
      onDatesChange={params => {
        const selectedDate = moment(params[focusedInput]).format('YYYY-MM-DD');
        const tmpStartDate = moment(params[focusedInput]).startOf('week');
        const tmpEndDate = moment(params[focusedInput]).endOf('week');
        setState({
          ...state,
          startDate: tmpStartDate,
          endDate: tmpEndDate
        });
        window.location.href = `?date=${selectedDate}`;
      }}
      focusedInput={focusedInput}
      startDate={startDate}
      endDate={endDate}
    />
  );
};

export default Calendar;

Calendar.propTypes = {
  date: PropTypes.string.isRequired
};