import React from 'react';
import PropTypes from 'prop-types';
import {BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend} from 'recharts';

const Graph = ({data}) =>
  <BarChart data={data} height={320} layout='horizontal' width={500}>
    <CartesianGrid strokeDasharray='3 3'/>
    <XAxis dataKey='weekDay' tick={{fontSize: 13}}/>
    <YAxis label={{value: '# of exercises', angle: -90, position: 'center'}}/>
    <Tooltip/>
    <Legend/>
    <Bar dataKey='complete' fill='#a0cd3a' name='Completed' stackId='a'/>
    <Bar dataKey='unable' fill='#f7cd18' name="Can't do it" stackId='a'/>
    <Bar dataKey='skipped' fill='#acb2b5' name="Don't feel like it" stackId='a'/>
    <Bar dataKey='incomplete' fill='#dee4e7' name='Incomplete' stackId='a'/>
  </BarChart>;

export default Graph;

Graph.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};